import Vue from 'vue'
import Router from 'vue-router'
// import tiktokAD from './views/tiktokAD/index.vue'
import baidu from './views/360AD/index.vue'





Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [{
        path: '/',
        redirect: "baidu"
    },
    
  
    {
        path: '/douyin',
        component: () => import('@/views/tiktokAD/index'),
    },
    {
        path: '/douyinA',
        component: () => import('@/views/douyin2/douyinA.vue'),
    },
    {
        path: '/douyinB',
        component: () => import('@/views/douyin2/douyinB.vue'),
    },
    {
        path: '/douyinC',
        component: () => import('@/views/douyin2/douyinC.vue'),
    },
    
    {
        path: '/douyinD',
        component: () => import('@/views/douyin2/douyinD.vue'),
    },
    {
        path: '/douyinE',
        component: () => import('@/views/douyin2/douyinE.vue'),
    },
    
    {
        path: '/douyinF',
        component: () => import('@/views/douyin2/douyinF.vue'),
    },
    
    
   
    
    {
        path: '/douyinG',
        component: () => import('@/views/douyin2/douyinG.vue'),
    },
    {
        path: '/douyinH',
        component: () => import('@/views/douyin2/douyinH.vue'),
    },
    {
        path: '/douyinI',
        component: () => import('@/views/douyin2/douyinI.vue'),
    },
    {
        path: '/baidu',
        component: baidu
    },
    {
        path: '/txqrcode',
        component: () => import('@/views/qrCode/index'),
    },
    {
      path: '/txqrcodeA',
      component: () => import('@/views/qrCode/indexA'),
  },
    {
        path: '/txyjQrcode',
        component: () => import('@/views/qrCode/txyjQrcode.vue'),
    },
    
    {
        path: '/360',
        component: () => import('@/views/360/three'),
    }
    ,
    {
        path: '/360A',
        component: () => import('@/views/360/threeB'),
    },
    {
        path: '/tengxun',
        component: () => import('@/views/tengxun/tengxun'),
    }
    ,
    {
        path: '/wenda',
        component: () => import('@/views/components/wenda.vue'),
    }
    ,
    {
        path: '/kuaishouA',
        component: () => import('@/views/kuaishou/kuaishouA.vue'),
    },
    // 食疗落地页
    // 快手食疗
    {

      path: '/ksFoodA',
      component: () => import('@/views/foodKuaiShou/foodKuaiShouA.vue'),
    },
    {

      path: '/ksFoodAPay',
      component: () => import('@/views/foodKuaiShou/ksFoodAPay.vue'),
    },
    // 百度食疗落地页
    {

      path: '/bdFoodA',
      component: () => import('@/views/foodBD/foodBdA.vue'),
    },
  
    {

      path: '/bdFoodAPay',
      component: () => import('@/views/foodBD/foodBdAPay.vue'),
    }  ,
    {

      path: '/bdFoodB',
      component: () => import('@/views/foodBD/foodBdB.vue'),
    }
    ,
    {

      path: '/bdBTest',
      component: () => import('@/views/foodBD/foodBcopy.vue'),
    }
    ,
    {

      path: '/bdFoodBPay',
      component: () => import('@/views/foodBD/foodBdBPay.vue'),
    }
    ,
    {

      path: '/bdFoodC',
      component: () => import('@/views/foodBD/foodBdC.vue'),
    }
    ,
    {

      path: '/bdFoodCPay',
      component: () => import('@/views/foodBD/foodBdCPay.vue'),
    }
    ,
    // b百度落地页
    {

      path: '/bdFoodBpay7',
      component: () => import('@/views/foodBD/foodBdBPay7.vue'),
    },
    {

      path: '/bdFoodCpay7',
      component: () => import('@/views/foodBD/foodBdCPay7.vue'),
    },
    
    {

      path: '/bdFoodApay7',
      component: () => import('@/views/foodBD/bdFoodApay7.vue'),
    },
    {

      path: '/bdFoodDpay1Nomobile',
      component: () => import('@/views/foodBD/foodBdDPay1Nomobile.vue'),
    }
    ,
    {

      path: '/bdFoodDpay7Nomobile',
      component: () => import('@/views/foodBD/foodBdDPay7Nomobile.vue'),
    }
    ,
    {

      path: '/bdFoodEpay1Nomobile',
      component: () => import('@/views/foodBD/foodBdEPay1Nomobile.vue'),
    }
    ,
    {

      path: '/bdFoodEpay7Nomobile',
      component: () => import('@/views/foodBD/foodBdEPay7Nomobile.vue'),
    }
    ,
    {

      path: '/bdFoodFpay1Nomobile',
      component: () => import('@/views/foodBD/foodBdFPay1Nomobile.vue'),
    }
    ,
    {

      path: '/bdFoodG',
      component: () => import('@/views/foodBD/bdFoodGNomobile.vue'),
    }
  

   
    ,
      // 腾讯 养生
      {

        path: '/txyangshengApay1',
        component: () => import('@/views/txYangsheng/txyangshengApay1.vue'),
      },
       // 腾讯落地页
    {

      path: '/txApay1',
      component: () => import('@/views/TX/txA.vue'),
  
    },

    {

      path: '/txApay1Nomobile',
      component: () => import('@/views/TX/txApay1Nomobile.vue'),
    },
    {

      path: '/txApay7Nomobile',
      component: () => import('@/views/TX/txApay7Nomobile.vue'),
    },
    {

      path: '/txApay1copy',
      component: () => import('@/views/TX/txA copy.vue'),
    },
    {

      path: '/txTgPayOk',
      component: () => import('@/views/TX/txPayOk.vue'),
    },
    {

      path: '/txApay7',
      component: () => import('@/views/TX/txApay7.vue'),
    }
    ,

    {

      path: '/dyApay1',
      component: () => import('@/views/dy/dyApay1.vue'),
    }
    // 食疗
    ,
    {
        path: '/shiliaoA',
        component: () => import('@/views/shangliaoyangsheng/shiliaoA.vue'),
        meta:{
            title:' 营养调理体验营'
        }
    },
    // 食疗问题页面
    {
        path: '/wenti',
        component: () => import('@/views/shangliaoyangsheng/wenda/shiliaowenda.vue'),
        meta:{
            title:' 健康调查问卷'
        }
    },
    // 血管健康
    {
        path: '/bloodInspect',
        component: () => import('@/views/shangliaoyangsheng/wenda/xueguanJiankang.vue'),
     
    },
      // 结果页
      {
        path: '/jieguo',
        component: () => import('@/views/shangliaoyangsheng/wendajieguo.vue'),
        meta:{
            title:' 健康问卷结果'
        }
    },
    
      // 血管結果
      {
        path: '/health',
        component: () => import('@/views/shangliaoyangsheng/jiankangjieguo.vue'),
        meta:{
            title:' 健康问卷结果'
        }
    },
    // 食物搜索页面
    {
        path: '/foodList',
        component: () => import('@/views/shiwushousuo/index.vue'),
        meta:{
            title:' 食物搜索'
        }
    },
     // 食物搜索列表页面
     {
        path: '/searchFood',
        component: () => import('@/views/shiwushousuo/foodList.vue'),
        meta:{
            title:' 食物搜索'
        }
    },
     // 食物详情
     {
        path: '/foodInfo',
        component: () => import('@/views/shiwushousuo/foodInfo.vue'),
        meta:{
            title:' 食物详情'
        }
    },
      // 详情列表
      {
        path: '/foodListAll',
        component: () => import('@/views/shiwushousuo/foodAll.vue'),
        meta:{
            title:' 食物详情'
        }
    },
     // BIM计算页
     {
        path: '/bmiIndex',
        component: () => import('@/views/BIM/index.vue'),
        meta:{
            title:'BMI计算'
        }
    },
    // BIM计算结果页
    {
        path: '/bmiResult',
        component: () => import('@/views/BIM/bimJieguo.vue'),
        meta:{
            title:'BIM计算结果'
        }
    },
     // 健康
    //  疾病卡片
     {
        path: '/illnessCard',
        component: () => import('@/views/healthMap/illnessCard.vue'),
        meta:{
            title:'疾病卡片'
        }
    },
     //  养肝护肝
     {
        path: '/liver',
        component: () => import('@/views/healthMap/liver.vue'),
        meta:{
            title:'养肝护肝指南'
        }
    },
    //  健康地图
    {
        path: '/map',
component: () => import('@/views/healthMap/indecMap.vue'),
        meta:{
            title:'养肝护肝指南'
        }
    },
    // 推广 直接支付
{
  path: '/tgH5Pay',
  component: () => import('@/views/tgH5Pay/index.vue'),
  meta: {
      title: '商品'
  }
},
{
  path: '/tgH5PayOk',
  component: () => import('@/views/tgPayOk/index.vue'),
  meta: {
      title: '商品'
  }
},
// 瑜伽落地页
{
  path: '/txyujiaApay1Nomobile',
  component: () => import('@/views/yujia/yujiaA.vue'),
  meta: {
  }
},
{
  path: '/txyujiaBpay1Nomobile',
  component: () => import('@/views/yujia/txyujiaBpay1Nomobile.vue'),
  meta: {
  }
},
{
  path: '/txyujiaCpay1Nomobile',
  component: () => import('@/views/yujia/txyujiaCpay1Nomobile.vue'),
  meta: {
  }
},
{
  path: '/txyujiaDpay1Nomobile',
  component: () => import('@/views/yujia/txyujiaDpay1Nomobile.vue'),
  meta: {
  }
},
{
  path: '/txyujiaEpay1Nomobile',
  component: () => import('@/views/yujia/txyujiaEpay1Nomobile.vue'),
  meta: {
  }
},
{
  path: '/txyujiaFpay1Nomobile',
  component: () => import('@/views/yujia/txyujiaFpay1Nomobile.vue'),
  meta: {
  }
},
{
  path: '/yujiaCode',
  component: () => import('@/views/yujia/yujiaCodePayOk.vue'),
  meta: {
  }
},
{
  path: '/test',
  component: () => import('@/views/test/testA.vue'),
  meta: {
  }
},
// 百度瑜伽落地页
{
  path: '/bdyujiaA',
  component: () => import('@/views/yuJiaBD/bdyujiaA.vue'),
  meta: {
    title: '瑜伽养生'

  }
},
{
  path: '/bdyujiaApay1',
  component: () => import('@/views/yuJiaBD/bdyujiaApay1.vue'),
  meta: {
    title: '瑜伽养生'
  }
},
    ]
})


// 为路由对象，添加before 导航守卫
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
    document.title = to.meta.title
        
    }
    next();
})

export default router